<template>
  <loading :active="isLoading" :is-full-page="true" :loader="'bars'" />
  <transition name="modal">
    <form autocomplete="off" novalidate>
      <div id="stripe-form" class="items-center justify-center modal-mask">
        <div class="flex items-center justify-center h-full">
          <div class="add-card-popup-demo">
            <div class="popup-header-inner">
              <p @click="exit" class="cursor-pointer"><a>Cancel</a></p>
              <h4>Add New Card</h4>
              <p @click="addCard()" v-if="!isLoading" class="cursor-pointer">
                <a>Add</a>
              </p>
              <p class="cursor-pointer" v-else><a>Processing...</a></p>
            </div>
            <div class="popup-inner-content">
              <div class="form-group">
                <label for="exampleInputEmail1">NAME THIS CARD</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Johnʼs Visa Debit"
                  v-model="input.cardName"
                  @blur="onBlur('cardName')"
                  @input="cardNameVerification"
                />
                <p
                  class="
                    text-danger text-xs text-left text-red-500
                    errorMsg
                    mb-1
                    mt-1
                  "
                  v-if="!validStatus.cardName"
                >
                  {{ errors.cardName }}
                </p>
              </div>
              <p>
                The name that appears for this payment method on the SMS Top Up
                screen.
              </p>
              <div class="card-information">
                <h2>CARD INFORMATION</h2>
                <div class="card-input-content">
                  <div class="card-input">
                    <label for="cardName" class="card-input__label"
                      >Cardholder Name</label
                    >
                    <input
                      type="text"
                      id="cardName"
                      placeholder="John Appleseed"
                      class="card-input__input"
                      v-model="input.cardHolder"
                      @blur="onBlur('cardHolder')"
                      @input="cardHolderVerification"
                    />
                  </div>
                  <p
                    class="
                      text-danger text-xs text-left text-red-500
                      errorMsg
                      mb-1
                      mt-1
                    "
                    v-if="!validStatus.cardHolder"
                  >
                    {{ errors.cardHolder }}
                  </p>
                  <!-- <div class="card-input">
                    <label for="cardName" class="card-input__label"
                      >Card Holders</label
                    >
                    <input
                      type="text"
                      id="cardName"
                      placeholder="John Appleseed"
                      class="card-input__input"
                    />
                  </div> -->
                  <div class="card-input">
                    <div
                      ref="card_element"
                      id="card-element"
                      class="w-full p-4 mx-1 my-2 bg-gray-200"
                    />
                  </div>
                </div>
                <p>
                  Once you tap ‘Addʼ, your card details entered above will be
                  available for use as a payment method for SMS Account top ups.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </transition>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import Loading from "vue-loading-overlay";

export default {
  emits: ["exit", "cardAdded"],
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      stripe: {},
      blurStatus: {
        cardName: "",
        cardHolder: "",
      },
      input: {
        cardName: "",
        cardHolder: "",
      },
      errors: {
        cardName: "",
        cardHolder: "",
      },
      validStatus: {
        cardName: true,
        cardHolder: true,
      },
      card: {},
      style: {
        base: {
          color: "#000",
          fontWeight: 500,
          fontFamily: "Source Code Pro, Consolas, Menlo, monospace",
          fontSize: "16px",
          fontSmoothing: "antialiased",
        },
        invalid: {
          color: "#FC011F",
          "::placeholder": {
            color: "#FFCCA5",
          },
        },
      },
    };
  },
  methods: {
    addCard() {
      this.onBlur("cardHolder");
      this.onBlur("cardName");
      if (this.validStatus.cardName && this.validStatus.cardHolder) {
        this.isLoading = true;
        let additionalData = {
          name: this.input.cardHolder,
        };
        let that = this;
        this.stripe
          .createToken(this.card, additionalData)
          .then((result) => {
            if (result.error) {
              console.log(result.error);
              // Inform the user if there was an error.
              // var errorElement = document.getElementById("card-errors");
              // errorElement.textContent = result.error.message;

              that.loading(false);
              that.$swal.fire({
                icon: "error",
                title: result.error.message,
                timer: 3000,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
              });
              console.log("createToken errrror");
            } else {
              that.createPaymentMethod(result.token);
            }
          })
          .catch((err) => {
            console.log("err in create token", err);
          });
      }
    },

    createPaymentMethod(token) {
      let that = this;
      this.stripe
        .createPaymentMethod({
          type: token.type,
          card: this.card,
          billing_details: {
            name: this.input.cardHolder,
          },
        })
        .then(function (result) {
          // Handle result.error or result.paymentMethod
          if (result.error) {
            // Inform the user if there was an error.

            that.isLoading = false;
            that.$swal.fire({
              icon: "error",
              title: result.error.message,
              timer: 3000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
          } else {
            // Send the token to server.
            that.createVendorNewCard(token, result.paymentMethod);
          }
        })
        .catch((err) => {
          console.log("errrrrr", err);
        });
    },

    createVendorNewCard(token, paymentMethod) {
      let body = {
        token_id: token.id,
        paymentmethod_id: paymentMethod.id,
      };
      this.$http
        .post("card/store", body)
        .then((response) => {
          this.response = response.data;
          let data = {
            val: "defaultPaymentMethod",
            e: token.card.last4,
          };
          this.$swal.fire({
            icon: "success",
            title: response.data.message,
            timer: 5000,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
          });
          // this.$swal.fire({
          //   toast: true,
          //   position: "top-end",
          //   showConfirmButton: false,
          //   timer: 4000,
          //   icon: "success",
          //   title: "Successfully Added New Card"
          // });
          this.createSubscription();
        })
        .catch((error) => {
          // this.loading(false);
          this.isLoading = false;
          console.error("There was an error!", error);
          this.$swal.fire({
            icon: "error",
            text: error.response.data.message,
            timer: 5000,
          });
        });
    },

    createSubscription() {
      this.$http
        .post("subscription/create", {})
        .then((response) => {
          if (response.data && response.data.status) {
            this.$swal.fire({
              icon: "success",
              title: response.data.message,
              timer: 5000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
            this.isLoading = false;
            console.log(response.data);
            if (
              response.data.data.latest_invoice &&
              response.data.data.latest_invoice.payment_intent &&
              response.data.data.latest_invoice.payment_intent.next_action
            ) {
              if (
                response.data.data.latest_invoice.payment_intent.next_action
                  .use_stripe_sdk &&
                response.data.data.latest_invoice.payment_intent.next_action
                  .use_stripe_sdk.stripe_js
              ) {
                window.open(response.data.data.latest_invoice.payment_intent.next_action
                  .use_stripe_sdk.stripe_js);
              }
            }
            this.$emit("exit");
          } else {
            this.$swal.fire({
              icon: "error",
              title: response.data.message,
              timer: 5000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.error("There was an error!", error);
          this.$swal.fire({
            icon: "error",
            text: error.response.data.message,
            timer: 5000,
          });
        });
    },
    cardNameVerification() {
      if (this.blurStatus.cardName) {
        if (this.input.cardName) {
          if (this.input.cardName.length > 100) {
            this.errors.cardName = "Card name should not be greator than 100";
            this.validStatus.cardName = true;
          } else {
            this.errors.cardName = "";
            this.validStatus.cardName = true;
          }
        } else {
          this.errors.cardName = "Card name is required";
          this.validStatus.cardName = false;
        }
      } else {
        this.errors.cardName = "";
        this.validStatus.cardName = true;
      }
    },
    cardHolderNameVerification() {
      if (this.blurStatus.cardHolder) {
        if (this.input.cardHolder) {
          if (this.input.cardHolder.length > 100) {
            this.errors.cardHolder =
              "Cardholder name should not be greator than 100";
            this.validStatus.cardHolder = false;
          } else {
            this.errors.cardHolder = "";
            this.validStatus.cardHolder = true;
          }
        } else {
          this.errors.cardHolder = "Cardholder name is required";
          this.validStatus.cardHolder = false;
        }
      } else {
        this.errors.cardHolder = "";
        this.validStatus.cardHolder = true;
      }
    },
    onBlur(field) {
      switch (field) {
        case "cardHolder":
          this.blurStatus.cardHolder = true;
          this.cardHolderNameVerification();
          break;
        case "cardName":
          this.blurStatus.cardName = true;
          this.cardNameVerification();
          break;
        default:
          break;
      }
    },
    exit() {
      this.$emit("exit");
    },
    initStripe() {
      this.$nextTick(async () => {
        this.stripe = await loadStripe(process.env.VUE_APP_PUBLISHABLE_KEY);
        let elements = this.stripe.elements();
        this.card = elements.create("card", {
          hidePostalCode: true,
          style: this.style,
        });
        this.card.mount(this.$refs.card_element);
      });
    },
  },
  mounted() {
    this.initStripe();
  },
};
</script>


<style scoped>
.add-card-popup-demo {
  background: #dee2e9;
  width: 40%;
  margin: 0 auto;
  height: 700px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}
.popup-header-inner {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  background: #fff;
  border-radius: 10px 10px 0 0;
}
.popup-inner-content .form-group label {
  display: block;
  width: 100%;
  text-align: left;
  margin-left: 20px;
  margin-bottom: 12px;
}
.popup-inner-content .form-group input.form-control {
  width: 100%;
  border-radius: 10px;
  border: 0;
  padding: 0 20px;
  min-height: 40px;
}
.popup-inner-content {
  padding: 30px;
}
.popup-header-inner h4 {
  font-size: 20px;
  color: #000;
  font-weight: 600;
}
.popup-header-inner p a {
  font-size: 20px;
  font-weight: 400;
  color: rgba(0, 154, 115, 1);
}
.popup-inner-content p {
  font-size: 18px;
  color: #857f7f;
  margin: 10px 0;
  text-align: left;
  margin-left: 20px;
}
.card-information {
  text-align: left;
  margin-top: 20px;
}
.card-information .card-input input#cardName {
  border: 0;
  max-width: 70%;
  flex: 0 0 70%;
  font-size: 20px;
}
.card-information .card-input {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}
.card-information .card-input label.card-input__label {
  max-width: 30%;
  flex: 0 0 30%;
  font-size: 20px;
  color: #000;
  font-weight: 500;
}
.card-input-content {
  border-radius: 5px;
  background: #fff;
  padding: 0px 20px;
  margin-bottom: 30px;
}
.card-information .card-input:last-child {
  border-bottom: 0;
}
:focus {
  border: 0;
  outline: 0;
  box-shadow: none;
}
.card-information h2 {
  margin-left: 20px;
  margin-bottom: 12px;
}
.text-danger {
  color: rgba(239, 68, 68, 1) !important;
  font-size: 16px !important;
}
</style>